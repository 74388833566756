var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('b-form', [_c('b-row', _vm._l(_vm.data, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": item.name
      }
    }, [_c('b-input-group', [_c('b-form-input', {
      attrs: {
        "placeholder": "Input"
      },
      model: {
        value: item.komisi_persentase,
        callback: function callback($$v) {
          _vm.$set(item, "komisi_persentase", $$v);
        },
        expression: "item.komisi_persentase"
      }
    }), _c('b-input-group-append', [_c('b-button', {
      attrs: {
        "variant": "outline-secondary"
      }
    }, [_vm._v(" %")])], 1), _c('b-input-group-append', [_c('b-button', {
      attrs: {
        "type": "submit",
        "variant": "gradient-primary"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.submit(item);
        }
      }
    }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('b-card', [_c('b-form', [_c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Target Komisi"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Masukkan Target Komisi"
    },
    model: {
      value: _vm.target_komisi,
      callback: function callback($$v) {
        _vm.target_komisi = $$v;
      },
      expression: "target_komisi"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "gradient-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submitTarget();
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }